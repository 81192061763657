import { nextTick } from "vue";
import { Position, dropdownDirections } from "./types";

export const computePosition = async (
    clickArea: HTMLElement | undefined,
    dropdownArea: HTMLElement | undefined,
    direction: dropdownDirections
): Promise<Position | null> => {
    if (!clickArea) return null;
    const { top, left, right, height, width } = clickArea.getBoundingClientRect();

    let positionLeft, positionTop;

    switch (direction) {
        case "right": {
            positionLeft = right;
            positionTop = top - 4;
            break;
        }
        // "automatic" | "bottom"
        default: {
            positionLeft = left;
            positionTop = top + height;
        }
    }

    if (!dropdownArea) return { top: positionTop, left: positionLeft };
    await nextTick();
    const dropdownContentAreaSizes = dropdownArea.getBoundingClientRect();
    const xExceeds = dropdownContentAreaSizes.width + left;
    const yExceeds = dropdownContentAreaSizes.height + top;

    if (xExceeds >= window.innerWidth) {
        positionLeft = left - dropdownContentAreaSizes.width + width;
    }
    if (yExceeds >= window.innerHeight) {
        const newY = top - dropdownContentAreaSizes.height;
        if (newY > 0) {
            positionTop = newY;
        }
    }
    return { top: positionTop, left: positionLeft };
};
